import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const box = css`
  text-align: center;

  @media (max-width: ${dimensions.phoneLargeMax}) {
    max-width: 25rem;
    width: 100%;
    margin: 0 auto;
  }
`;
