/* eslint-disable @typescript-eslint/naming-convention */
import { useTheme } from "@emotion/react";
import type { JSX } from "@emotion/react/jsx-runtime";
import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { colorProfileMapper, type AviaryColors } from "aviary-tokens";
import type { HTMLProps, SVGProps } from "react";

import type { AviarySize } from "@aviary/types";
import { FontAwesomeIcon } from "@shared/react-fontawesome";

import * as styles from "./CircularIcon.styles";

// Info to be removed for DS4, keeping temporarily for CPW. Blends into the background in DS3
type CircularIconColors = Extract<AviaryColors, "success" | "system">;

type CircularIconAviarySize = Extract<AviarySize, "xsmall" | "small" | "medium" | "large">;

interface Props extends Omit<HTMLProps<HTMLDivElement>, "size"> {
  /**
   *  The icon to be rendered
   */
  icon?: IconDefinition;
  /**
   * Turns the Icon light to be used on dark backgrounds
   *
   * @default false
   */
  isLight?: boolean;
  /**
   *  The svg to be rendered
   */
  Svg?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  /**
   * size of the CircularIcon
   *
   * @default medium
   */
  size?: CircularIconAviarySize;
  /**
   * Allows passing a count "value" as icon
   */
  count?: number;
  /**
   * Preserves the colors of custom SVG's that are passed in
   *
   * @default false
   */
  isColorPreserved?: boolean;
  /**
   * Colour intention
   *
   * @default "system"
   */
  isColor?: CircularIconColors;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/display/CircularIcon
 */
const CircularIcon = ({
  icon,
  Svg,
  count,
  size = "medium",
  isColor = "system",
  isColorPreserved,
  ...rest
}: Props) => {
  const currentTheme = useTheme();

  const themeColors = colorProfileMapper(currentTheme);

  const iconStyles = [
    styles.baseStyles,
    styles.size[size],
    styles.colorStyles[isColor],
    !isColorPreserved && styles.iconFillColor[isColor],
  ];

  const countStyles = [styles.countSize[size], styles.countColors(themeColors[isColor])];

  const renderIconSize = () => {
    switch (size) {
      case "xsmall":
        return "xs";
      case "small":
      case "medium":
        return "1x";
      case "large":
        return "lg";
    }
  };

  const getCount = () => {
    if (count > 99) {
      return "99+";
    }

    return count;
  };

  const renderContent = () => {
    if (icon && Svg) {
      return null;
    }
    if (count) {
      return <span css={countStyles}>{getCount()}</span>;
    }
    if (icon) {
      return <FontAwesomeIcon icon={icon} size={renderIconSize()} />;
    }
    if (Svg) {
      return <Svg />;
    }
  };

  return (
    <div css={iconStyles} {...rest}>
      {renderContent()}
    </div>
  );
};

export { CircularIcon, type CircularIconColors, type CircularIconAviarySize };
