import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

import type { TwoFactorAuthenticationMethods } from "@shared/types/graphqlGenerated";

const EmersonUserSignIn_UnauthenticatedShared_Mutation = gql`
  mutation EmersonSignIn_UnauthenticatedShared_Mutation($input: EmersonAuthUserSignInInput!) {
    auth {
      emersonSignIn(input: $input) {
        user {
          id
        }
        userEmail
        redirectPath
        twoFactorAuthenticationToken
        otpMethod
        redirectPath
        phoneLast4

        errors {
          message
        }
      }
    }
  }
`;

interface EmersonAuthUserSignInInput {
  email: string;
  password: string;
  staySignedIn?: boolean;
  rememberMe?: boolean;
  optional?: any;
  intake?: boolean;
}

interface Variables {
  input: {
    attributes: EmersonAuthUserSignInInput;
    captchaToken: string;
  };
}

interface EmersonUserSignInError {
  message: string;
}

interface EmersonUser {
  id: string;
}

interface EmersonUserSignInMutationData {
  auth: {
    emersonSignIn: {
      user: EmersonUser;
      redirectPath: string;
      userEmail: string;
      errors: EmersonUserSignInError;
      twoFactorAuthenticationToken: string;
      otpMethod: TwoFactorAuthenticationMethods;
      phoneLast4: string;
    };
  };
}

const useEmersonUserSignIn = (
  options?: MutationHookOptions<EmersonUserSignInMutationData, Variables>
) =>
  useMutation<EmersonUserSignInMutationData, Variables>(
    EmersonUserSignIn_UnauthenticatedShared_Mutation,
    options
  );

export type { Variables, EmersonUserSignInMutationData };

export { EmersonUserSignIn_UnauthenticatedShared_Mutation, useEmersonUserSignIn };
