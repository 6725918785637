import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { Arrange } from "@aviary";
import { useIsDS4Enabled } from "@aviary/hooks/useIsDS4Enabled";
import { SvgHipaaLogoPrimary, SvgHipaaLogoWhite } from "@shared/assets/logo";
import { useSharedGlobalConfig } from "@shared/hooks";
import { l } from "@shared/locales/i18n";

const FooterLogos = () => {
  const { t } = useTranslation();
  const { isUS, isEmerson } = useSharedGlobalConfig();
  const currentTheme = useTheme();
  const isDS4Enabled = useIsDS4Enabled();

  const isDS4DarkMode = isDS4Enabled && currentTheme.theme === "dark";

  const renderHipaaLogo = () => {
    if (isDS4DarkMode) {
      return <SvgHipaaLogoWhite role="img" aria-label={t(l.aviary.footer.HIPAAalttext)} />;
    }

    return <SvgHipaaLogoPrimary role="img" aria-label={t(l.aviary.footer.HIPAAalttext)} />;
  };

  return (
    <Arrange isWrapped={false} spacing="none" justify="center">
      {isUS && !isEmerson && renderHipaaLogo()}
    </Arrange>
  );
};

export { FooterLogos };
