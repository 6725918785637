const e2eAttributes = {
  login: {
    signInButton: "sign-in-button",
    signInEmail: "sign-in-email",
    signInPassword: "sign-in-password",
    createPractitionerLink: "create-practitioner-link",
    emersonLogo: "emerson-logo",
  },
} as const;

export { e2eAttributes };
