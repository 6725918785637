import type { SerializedStyles } from "@emotion/serialize";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Message, Spacer, Typography } from "@aviary";
import { useIsWebview, useCopyToClipboard } from "@shared/hooks";
import { l } from "@shared/locales/i18n";
import type { PatientAdditionalAttributesType } from "@shared/types/PatientAdditionalAttributesType";
import type { PractitionerAdditionalAttributesType } from "@shared/types/PractitionerAdditionalAttributesType";

import { getActionURL } from "../utils/getActionURL";

import GoogleGSVGComponent from "./assets/GoogleG";

import * as styles from "./GoogleSSOButton.styles";

interface Props {
  formData?: {
    firstName?: string;
    lastName?: string;
    storeName?: string;
    tosAcceptedAt?: string;
  };
  treatmentPlanSlug?: string;
  labRecommendationId?: string;
  storeSlug?: string;
  pracTypeId?: string;
  signUpPage?: string;
  pageVersion?: string;
  disabled?: boolean;
  isOrTextRendered?: boolean;
  additionalAttributes?: PractitionerAdditionalAttributesType & PatientAdditionalAttributesType;
  uuid?: string;
  buttonStyles?: SerializedStyles;
  orStyles?: SerializedStyles;
  isIntake?: boolean;
  requestedPractitionerId?: string;
}

const GoogleSSOButton = ({
  formData,
  signUpPage,
  treatmentPlanSlug,
  labRecommendationId,
  storeSlug,
  pracTypeId,
  pageVersion,
  disabled,
  isOrTextRendered,
  additionalAttributes,
  uuid,
  buttonStyles,
  orStyles,
  isIntake,
  requestedPractitionerId,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard();

  const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute("content");
  const buttonText = t(l.googleSSO.ContinueWithGoogle);

  const formStyles = [styles.form, disabled && styles.formDisabled, buttonStyles];
  const submitStyles = [styles.submit, disabled && styles.submitDisabled];
  const logoStyles = [styles.logo, disabled && styles.disabled];
  const googleSSOButtonStyles = [styles.GoogleSSOButton, disabled && styles.disabled];
  const isWebview = useIsWebview();

  const [logInWithGoogleClickedOnWebview, setLogInWithGoogleClickedOnWebview] = useState(false);

  const handleCopyLink = async () => {
    const { redirectPath } = additionalAttributes ?? {};
    if (redirectPath?.startsWith("http")) {
      await copyToClipboard(redirectPath, { successMessage: t(l.sharedCommon.linkCopied) });
    } else {
      await copyToClipboard(window.location.href, { successMessage: t(l.sharedCommon.linkCopied) });
    }
  };

  if (isWebview) {
    return (
      <div css={styles.buttonWrapper} onClick={() => setLogInWithGoogleClickedOnWebview(true)}>
        <div css={formStyles} {...rest}>
          <label aria-label={buttonText} css={googleSSOButtonStyles} htmlFor={buttonText}>
            <GoogleGSVGComponent css={logoStyles} />
            <input
              id={buttonText}
              value={buttonText}
              type="submit"
              css={submitStyles}
              disabled={disabled}
            />
          </label>
        </div>
        {logInWithGoogleClickedOnWebview && (
          <Message color="system" css={styles.continueInBrowserMessage}>
            <Typography isMarginless>{t(l.googleSSO.ContinueWithGoogleInBrowser)}</Typography>
            <Spacer height="one" />
            <Button
              size="small"
              isColor="system"
              aria-label={t(l.sharedCommon.copyLink)}
              onClick={handleCopyLink}
            >
              {t(l.sharedCommon.copyLink)}
            </Button>
          </Message>
        )}
      </div>
    );
  }

  return (
    <>
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap"
        rel="stylesheet"
      ></link>
      <div css={styles.buttonWrapper} data-e2e="new-sign-in-flow">
        <div css={formStyles} {...rest}>
          <form
            action={getActionURL(
              "Google",
              formData,
              additionalAttributes,
              signUpPage,
              treatmentPlanSlug,
              labRecommendationId,
              storeSlug,
              pracTypeId,
              pageVersion,
              uuid,
              isIntake,
              requestedPractitionerId
            )}
            method="post"
            data-e2e="google-sso-button"
          >
            <label aria-label={buttonText} css={googleSSOButtonStyles} htmlFor={buttonText}>
              <GoogleGSVGComponent css={logoStyles} />
              <input
                id={buttonText}
                value={buttonText}
                type="submit"
                css={submitStyles}
                disabled={disabled}
              />
            </label>
            <input type="hidden" name="authenticity_token" value={csrf}></input>
          </form>
        </div>
        {isOrTextRendered && (
          <Typography type="footnote" isMarginless css={[styles.orSection, orStyles]}>
            {t(l.googleSSO.Or)}
          </Typography>
        )}
      </div>
    </>
  );
};

export { GoogleSSOButton };
