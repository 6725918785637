/* eslint-disable @typescript-eslint/naming-convention */
import { isProduction } from "@shared/utils";

const EE_BASE_AUTH_URL = isProduction() ? "" : "/emerson";

const authRoutes = {
  base: "/",
  wellevate_patients_authorize: "/wellevate/patients/authorize",
  practitioner_sign_up: "/practitioner-signup",
  patient_sign_up: "/patient-signup",
  continue_without_password: "/continue-without-password",
  login: "/login",
  forgot_password: "/password",
  edit_password: "/edit_password",
  emerson: {
    base: EE_BASE_AUTH_URL,
    accountSettings: `${EE_BASE_AUTH_URL}/account/security`,
    sign_up: `${EE_BASE_AUTH_URL}/practitioner-signup`,
    login: `${EE_BASE_AUTH_URL}/login`,
    forgot_password: `${EE_BASE_AUTH_URL}/password`,
    edit_password: `${EE_BASE_AUTH_URL}/edit_password`,
  },
  usApp: "https://us.fullscript.com",
  caApp: "https://ca.fullscript.com",
  intake_sign_up: (storeSlug = ":storeSlug") => getWelcomeURL(storeSlug, null, `intake`),
  rx_sign_in: "/welcome/:practitionerSlug/:tpSlug",
  rx_sign_up: (practitionerSlug = ":practitionerSlug", tpSlug = ":tpSlug") =>
    `/welcome/${practitionerSlug}/${tpSlug}/signup`,
  welcome_sign_up: (storeSlug = ":storeSlug", isPreview = false) =>
    getWelcomeURL(storeSlug, null, `signup${isPreview ? "?preview=true" : ""}`),
  welcome_start: (storeSlug = ":storeSlug", identitySlug) =>
    getWelcomeURL(storeSlug, identitySlug, "store-start"),
  welcome_password: (storeSlug = ":storeSlug", identitySlug) =>
    getWelcomeURL(storeSlug, identitySlug, "store-password"),
  welcome_activation: (storeSlug = ":storeSlug", identitySlug) =>
    getWelcomeURL(storeSlug, identitySlug, "activation"),
  welcome_forgot_password: (storeSlug = ":storeSlug", identitySlug) =>
    getWelcomeURL(storeSlug, identitySlug, "forgot-password"),
} as const;

/** welcome path has two paths, but render the same page */
const getWelcomeURL = (storeSlug, identitySlug, endPath) =>
  identitySlug
    ? `/welcome/${storeSlug}/${identitySlug}/${endPath}`
    : `/welcome/${storeSlug}/${endPath}`;

export { authRoutes };
